/* ------------------------------ FOR IMAC ------------------------------ */
.main {
    display: flex;
    height: 100vh;
    background-image: url("background-small.jpg");
    background-size: cover;
    /* for construction page only  */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
  }

  /* for construction page only  */
  /* .home-under-construction {
    color: #ffffff;
    font-family: "Avenir";
    font-weight: bold;
    font-size: 24px;
  } */

  /* for construction page only */
  /* .feast-finder-link {
    color: #ffffff;
    transition: color 0.5s;
  }
  .feast-finder-link:hover {
    color: #B8BC00;
  } */

  /* header */
  .header {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
    margin-top: 20px;
  }

  .logo {
    width: 240px;
    height: 170px;
    background-image: url("logo.svg");
    background-size: 280px;
    background-repeat: no-repeat;
    margin-top: -90px;
    margin-right: 1500px;
    margin-left: -20px;
  }

  .nav-and-menu {
    display: flex;
    margin-left: 180px;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  
  .nav-text {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    transition: color 1s;
    text-decoration: none;
  }
  
  .nav-text:hover {
    color: #000000;
  }
  
  .menu {
    float: right;
    width: 35px;
    height: 35px;
    border: none;
    background-image: url("./menu.svg");
    background-repeat: no-repeat;
    background-size: 35px;
    background-color: transparent;
    cursor: pointer;
    visibility: hidden;
  }

  /* development */
  .development-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100vh;
    background-color: #B8BC00;
    opacity: 0.85;
    transition: opacity 1s;
  }

  .development-wrapper:hover {
    opacity: 1;
  }

  .development {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .development-title {
    width: 347px;
    height: 38px;
    background-image: url("development-title.png");
    background-size: 347px;
    background-repeat: no-repeat;
  }
  
  .development-links {
    text-align: center;
    margin-top: 25px;
  }
  
  .dev-link-text {
    font-family: "Menlo", sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
  }
  
  .dev-link-text:hover {
    border-bottom: 1px solid #ffffff;
  }
  
  .cursor {
    color: #ffffff;
    font-size: 20px;
    margin-left: 1px;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 1;
  }
  
  @keyframes blink {
  from { opacity: 1; }
  to { opacity: 0; }
  }
  
  /* design */
  .design-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100vh;
    background-color: #ff6839;
    opacity: 0.85;
    transition: opacity 1s;
  }

  .design-wrapper:hover {
    opacity: 1;
  }

  .design {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .design-title {
    position: sticky;
    top: 0;
    width: 344px;
    height: 95px;
    background-image: url("design-text.png");
    background-size: 344px;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 15px;
  }
  
  .design-content {
    width: 100%;
    max-height: 380px;
  }
  
  .design-links {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    color: #ffffff;
  }

  .design-projects {
    width: 550px;
    height: 510px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: scroll;
    z-index: 1;
  }

  .single-project {
    background-color: none;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: background-color 0.5s;
  }
  
  .single-project:hover {
    background-color: #9a431f;
  }

  .naked-travel-cover-image, .aviate-cover-image, .alex-campaign-cover-image, .elm-creek-cover-image {
    width: 360px;
    height: 270px;
    background-image: url("naked-travel-cover.png");
    background-size: 360px;
    background-repeat: no-repeat;
    margin: auto;
  }

  .aviate-cover-image {
    background-image: url("aviate-cover.png");
  }
  
  .alex-campaign-cover-image {
    background-image: url("alex-campaign-cover.png");
  }
  
  .elm-creek-cover-image {
    background-image: url("elm-creek-cover.png");
  }
  
  /* modal */
  .modal {
    position: absolute;
    z-index: 2;

    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
  }
  
  .project-name {
    font-size: 24px;
  }
  
  .prototype {
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .prototype:hover {
    border-bottom: 1px solid #ffffff;
  }
  
  .x {
    width: 30px;
    height: 30px;
    background-image: url("x-icon.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .project-images {
    width: 100%;
    height: 90%;
    overflow: scroll;
  }

  .project-image {
    display: block;
    width: 70%;
    height: auto;
    margin: auto;
    margin-bottom: 10px;
  }
  
  .logo:hover, .nav-text:hover, .development-title:hover, .development-links:hover,
  .design-title:hover, .design-projects {
    cursor: pointer;
  }

  .arrow-text {
    visibility: hidden;
  }
  
  /* footer */
  .footer {
    position: absolute;
    z-index: 1;
  }

  .social-icons {
    display: flex;
    margin-top: -80%;
    margin-left: 35%;
  }
  
  .linkedin-icon, .github-icon {
    width: 40px;
    height: 40px;
    background-image: url("./github-icon.png");
    background-size: 40px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  
  .linkedin-icon {
    margin-right: 15px;
    background-image: url("./linkedin-icon.png");
  }
  
  /* about and contact */
  .about, .contact {
    width: 100%;
    height: 100vh;
    background-image: url("./about-small.jpg");
    background-size: cover;
  }
  
  .contact {
    background-image: url("./contact-small.jpg");
  }
  
  .about-content, .contact-content {
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 104, 57, 0.8);
  }
  
  .contact-content {
    background-color: rgba(184,188,0,0.9)
  }
  
  .contact-info-wrapper {
    margin-top: 20px;
  }

  .intro, .contact-intro {
    width: 75%;
    margin: auto;
    padding-top: 700px;
    font-family: "Avenir", sans-serif;
    font-weight: bolder;
    color: #ffffff;
    font-size: 60px;
    line-height: 65px;
  }
  
  .contact-intro {
    text-align: right;
    padding-top: 250px;
  }

  .about-paragraph, .contact-info {
    width: 75%;
    margin: auto;
    margin-top: 20px;
    font-family: "Avenir", sans-serif;
    color: #ffffff;
    font-size: 28px;
  }
  
  .contact-info {
    margin-top: 0px;
    text-align: right;
  }
  
  .menu-link {
    text-decoration: none;
  }
  
  .resume {
    color: #ffffff;
}

/* ------------------------------ FOR MACBOOK PRO ------------------------------ */  
@media only screen and (max-width: 1680px) {
  
  .logo {
    margin-right: 500px;
  }
  
  .design-projects {
    width: 350px;
    height: 310px;
  }
  
  .naked-travel-cover-image, .aviate-cover-image, .alex-campaign-cover-image, .elm-creek-cover-image {
    width: 260px;
    height: 185px;
    background-size: 260px;
  }
  
  .project-images {
    width: 80%;
    height: 500px;
    margin: auto;
  }

  .project-image {
    width: 65%;
  }
  
  .intro, .contact-intro {
    padding-top: 300px;
    font-size: 40px;
    line-height: 50px;
  }
  
  .about-paragraph, .contact-info {
    font-size: 20px;
  }
}

/* ------------------------------ FOR TABLET ------------------------------ */

@media only screen and (max-width: 768px) {
/* for construction page only  */
/* .home-under-construction {
  padding-top: 220px;
  width: 300px;
  margin: auto;
} */

.main {
  display: block;
  height: fit-content;
}

.logo {
  position: fixed;
  margin-left: -260px;
  margin-top: -15px;
}

.nav-and-menu {
  position: fixed;
  margin-top: 30px;
}

.development-wrapper, .design-wrapper {
  width: 100%;
}

.development, .design {
  width: 100%;
}

.development-arrow {
  width: 60px;
  height: 60px;
  background-image: url("./down-arrow.png");
  background-size: 60px;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 30px;
}

.modal-header {
  padding-top: 70px;
}

.design-content {
  max-height: 540px;
}

.design-projects {
  height: 400px;
  width: 500px;
}

.naked-travel-cover-image, .aviate-cover-image, .alex-campaign-cover-image, .elm-creek-cover-image {
  width: 340px;
  height: 255px;
  background-size: 340px;
}

.project-images {
  width: 95%;
  height: 80%;
}

.design-arrow {
  width: 60px;
  height: 60px;
  background-image: url("./up-arrow.png");
  background-size: 60px;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 30px;
}

.arrow-text {
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Avenir", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  transition: color 1s;
  text-align: center;
  visibility: visible;
}

.footer {
  visibility: hidden;
}

.about {
  background-position: center;
}

.intro {
  padding-top: 410px;
}

.contact-intro {
  padding-top: 300px;
}

}

/* ------------------------------ FOR MOBILE ------------------------------ */

@media only screen and (max-width: 375px) {

.header {
  margin-top: 0px;
}  

.logo {
  margin-right: -60px;
  margin-left: -90px;
  margin-top: -5px;
}  

.nav-and-menu {
  margin-left: 0px;
  margin-left: 80px;
  margin-top: 35px;
}  

.nav {
  visibility: hidden;
  width: 155px;
}

.menu {
  visibility: visible;
  float: right;
  width: 35px;
  height: 35px;
  border: none;
  background-image: url("./menu.svg");
  background-repeat: no-repeat;
  background-size: 35px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 25px;
}

.menu-block {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 100%;
  height: 200vh;
  background-color: #ff6839;
}

.menu-x {
  float: right;
  width: 35px;
  height: 35px;
  border: none;
  background-image: url("./x-icon.svg");
  background-repeat: no-repeat;
  background-size: 35px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 20px;
}

.menu-links {
  margin-top: 100px;
}

.menu-link {
  text-decoration: none;
}

.menu-text {
  width: 100%;
  height: 50px;
  font-family: "Avenir", sans-serif;
  font-size: 50px;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff;
}

.development-inner-wrapper {
  width: 100%;
}

.development-title {
  width: 250px;
  background-size: 250px;
}

.development-links {
  margin-top: 10px;
}

.dev-link-text, .cursor {
  font-size: 16px;
}

.development-arrow {
  width: 40px;
  height: 40px;
  background-size: 40px;
}

.design-arrow {
  width: 40px;
  height: 40px;
  background-size: 40px;
}

.design-title {
  width: 265px;
  background-size: 265px;
  margin-bottom: 0px;
}

.design-content {
  max-height: 415px;
}

.design-projects {
  width: 340px;
  height: 300px;
}

.naked-travel-cover-image, .aviate-cover-image {
  width: 325px;
}

.alex-campaign-cover-image, .elm-creek-cover-image {
  width: 345px;
}

.modal-header {
  margin-bottom: 20px;
}

.project-name, .prototype {
  font-size: 16px;
}

.project-name {
  width: 80px;
}

.x {
  width: 20px;
  background-size: 20px;
}

.project-images {
  height: 75%;
}

.project-image {
  width: 100%;
}

.intro {
  padding-top: 330px;
  font-size: 25px;
  line-height: 35px;
}

.about-paragraph {
  font-size: 16px;
}

}